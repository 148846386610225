<app-columnone>
    <!-- main app container -->
    <div class="card m-3">
        <h5 class="card-header">
            <i nz-icon nzTooltipTitle="Cliquez pour revenir" nzTooltipPlacement="top" [nzTooltipColor]="color" class="mr-1" style="font-size:1vw;" nzType="menu-fold" nzTheme="outline" nz-tooltip (click)="backToMenu()"></i> Editer {{editUser?.firstName}}
            {{editUser?.lastName}}
        </h5>
        <div class="card-body">
            <form #editUserForm="ngForm">
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="firstName">Prénom</label>
                        <input name="firstName" [(ngModel)]="editUser.firstName" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="lastName">Nom</label>
                        <input [(ngModel)]="editUser.lastName" name="lastName" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="username">Nom d'utilisateur</label>
                        <input [(ngModel)]="editUser.username" name="username" type="text" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="email">Adresse Email</label>
                        <input [(ngModel)]="editUser.email" name="email" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="phoneNumber">Téléphone</label>
                        <input (keypress)="keyPress($event)" name="phoneNumber" required type="text" [(ngModel)]="editUser.phoneNumber" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="fullNameEmergency">Nom et prénom du contact en cas d'urgence</label>
                        <input [(ngModel)]="editUser.fullNameEmergency" name="fullNameEmergency" type="text" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="phoneNumberEmergency">Téléphone en cas d'urgence</label>
                        <input name="phoneNumberEmergency" required type="text" [(ngModel)]="editUser.phoneNumberEmergency" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="homeAddress">Adresse du domicile</label>
                        <input [(ngModel)]="editUser.homeAddress" name="homeAddress" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="statusMarital">Statut matrimonial</label>
                        <select [(ngModel)]="editUser.statusMarital" name="statusMarital" class="form-select form-select-sm">
                        <option value="single">Célibataire</option>
                        <option value="married">Marié(e)</option>
                        <option value="widow">Veuve</option>
                        <option value="divorce">Divorcé(e)</option>
                    </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="dateBirth">Date de naissance</label>
                        <mat-form-field>
                            <input matInput [(ngModel)]="editUser.dateBirth" name="dateBirth" [matDatepicker]="picker" [max]="maxDob" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-4">
                        <label for="placeBirth">Lieu de naissance</label>
                        <input [(ngModel)]="editUser.placeBirth" name="placeBirth" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="nationality">Nationalité</label>
                        <input [(ngModel)]="editUser.nationality" name="nationality" type="text" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4 w-50">
                        <label for="managerUsername">Manager</label>
                        <select name="managerUsername" required [(ngModel)]="editUser.managerUsername" class="form-select form-select-sm">
                            <option *ngFor="let manager of managers" [value]="manager.username">
                                {{ manager.username }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-4">
                        <label for="role">Rôle attribué à l'utilisateur</label>
                        <select [(ngModel)]="editUser.role" name="role" class="form-select form-select-sm">
                            <option value="ROLE_ADMIN">ADMIN</option>
                            <option value="ROLE_USER">USER</option>
                            <option value="ROLE_LINE_MANAGER">LINE MANAGER</option>
                            <option value="ROLE_MIDDLE_MANAGER">MIDDLE MANAGER</option>
                            <option value="ROLE_TOP_MANAGER">TOP MANAGER</option>
                            <option value="ROLE_HR_MANAGER">HR MANAGER</option>
                    </select>
                    </div>
                    <div class="form-group col-4">
                        <label for="statusEmployer">Status de l'employé</label>
                        <select [(ngModel)]="editUser.statusEmployer" name="statusEmployer" class="form-select form-select-sm">
                        <option value="active">Actif</option>
                        <option value="inactive">Inactif</option>
                    </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4 w-50" style="display: grid;">
                        <label for="dateRecruitment">Date de recructement</label>
                        <mat-form-field>
                            <input matInput [(ngModel)]="editUser.dateRecruitment" name="dateRecruitment" [matDatepicker]="dateRecruitmentPicker" />
                            <mat-datepicker-toggle matSuffix [for]="dateRecruitmentPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateRecruitmentPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-4">
                        <label for="cin">Numéro CIN</label>
                        <input [(ngModel)]="editUser.cin" name="cin" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="cnss">Numéro CNSS</label>
                        <input [(ngModel)]="editUser.cnss" name="cnss" type="text" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-4">
                        <label for="affiliationRetirement">Affiliation Retraite</label>
                        <input [(ngModel)]="editUser.affiliationRetirement" name="affiliationRetirement" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="healthMembership">Affiliation Santé</label>
                        <input [(ngModel)]="editUser.healthMembership" name="healthMembership" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="incremental">Incrémental (Jours/Mois)</label>
                        <input (keypress)="keyPress($event)" [(ngModel)]="editUser.incremental" name="incremental" type="text" class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-row">
                    <div *ngIf="!active" class="form-group col-4 w-50" style="display: grid;">
                        <label for="dateDeactivation">date de désactivation</label>
                        <mat-form-field>
                            <input matInput [(ngModel)]="editUser.dateDeactivation" name="dateDeactivation" [matDatepicker]="dateDeactivationPicker" />
                            <mat-datepicker-toggle matSuffix [for]="dateDeactivationPicker"></mat-datepicker-toggle>
                            <mat-datepicker #dateDeactivationPicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-group col-4">
                        <label for="numberDaysLeave">Nombre de jours de congé à importer</label>
                        <input [(ngModel)]="editUser.numberDaysLeave" name="numberDaysLeave" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="jobFunction">Fonction</label>
                        <input [(ngModel)]="editUser.jobFunction" name="jobFunction" type="text" class="form-control form-control-sm">
                    </div>
                    <div class="form-group col-4">
                        <label for="profileImage">Photo de profil</label>
                        <div class="custom-file">
                            <input type="file" accept="image/*" name="profileImage" (change)="onProfileImageChange($event)" class="custom-file-input">
                            <label class="custom-file-label">
                            <span [hidden]="!fileName">{{fileName}}</span>
                            <span [hidden]="fileName">Choisir un fichier</span>
                        </label>
                        </div>
                    </div>
                </div>
                <fieldset class="form-group">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input name="active" (change)="isActive()" type="checkbox" [(ngModel)]="editUser.active"
                                class="form-check-input">
                            Active 
                        </label>
                    </div>
                    <div class="form-check disabled">
                        <label class="form-check-label">
                            <input name="locked" type="checkbox" [(ngModel)]="editUser.notLocked"
                                class="form-check-input">
                            Unlocked 
                        </label>
                    </div>
                </fieldset>
                <div class="row">
                    <div class="col d-flex justify-content-end">
                        <button type="button" (click)="onUpdateUser()" [disabled]="editUserForm.invalid" class="btn btn-gold"><i class="fa fa-floppy-o"></i>&nbsp;&nbsp; Enregistrer les modifications</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</app-columnone>