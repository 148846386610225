import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationType } from 'src/app/shared/enum/notification-type.enum';
import { City } from 'src/app/shared/model/city';
import { FileUploadStatus } from 'src/app/shared/model/file-upload.status';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { NotificationService } from 'src/app/shared/service/notification.service';
import { ResourceService } from 'src/app/shared/service/resource.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-adduserindividual',
  templateUrl: './adduserindividual.component.html',
  styleUrls: ['./adduserindividual.component.css']
})
export class AdduserindividualComponent implements OnInit, OnDestroy {
  public user!: User;
  public refreshing!: boolean;
  public fileName!: string;
  public files: any;
  public profileImage!: File;
  private subscriptions: Subscription[] = [];
  public fileStatus = new FileUploadStatus();
  public addUserForm!: FormGroup;
  public managers!: User[];
  public cities!: City[];
  readonly minAge = 18;
  public color = '#d2b248';
  maxDob!: Date;
  form!: FormGroup;
  submitted = false;

  pdfContent: any;
  @ViewChild('pdfview') pdfview!: ElementRef;

  constructor(private router: Router, private authenticationService: AuthenticationService, private formBuilder: FormBuilder,
    private userService: UserService, private notificationService: NotificationService, public datepipe: DatePipe,
    private resourceService: ResourceService) { }

  ngOnInit(): void {
    const today = new Date();
    this.maxDob = new Date(
      today.getFullYear() - this.minAge,
      today.getMonth(),
      today.getDate()
    );

    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      phoneNumberEmergency: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      fullNameEmergency: ['', Validators.required],
      homeAddress: ['', Validators.required],
      dateBirth: ['', Validators.required],
      placeBirth: ['', Validators.required],
      nationality: ['', Validators.required],
      dateRecruitment: ['', Validators.required],
      cin: ['', Validators.required],
      cnss: ['', Validators.required],
      numberDaysLeave: ['', Validators.required],
      managerUsername: ['', Validators.required],
      role: ['', Validators.required],
      affiliationRetirement: ['', Validators.required],
      healthMembership: ['', Validators.required],
      statusEmployer: ['', Validators.required],
      statusMarital: ['', Validators.required],
      incremental: ['', Validators.required],
      jobFunction: ['', Validators.required]
    });

    this.user = this.authenticationService.getUserFromLocalCache();
    this.getUsers(false);
    this.getCities(false);
  }
  public backToMenu() {
    this.router.navigateByUrl('/controlpanel/accessmanagement');
  }

  public getUsers(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.userService.getUsers().subscribe(
        (response: User[]) => {
          this.userService.addUsersToLocalCache(response);
          this.managers = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} user(s) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );

  }

  public getCities(showNotification: boolean): void {
    this.refreshing = true;
    this.subscriptions.push(
      this.resourceService.getCities().subscribe(
        (response: City[]) => {
          this.cities = response;
          this.refreshing = false;
          if (showNotification) {
            this.sendNotification(NotificationType.SUCCESS, `${response.length} city(ies) loaded successfully.`);
          }
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.refreshing = false;
        }
      )
    );
  }

  public showData(event: any) {
    const filesEvent = Object.keys(event.target.files).map((key: any) => event.target.files[key]);
    this.files = filesEvent[0];
    this.fileName = this.files.name;
    this.getBase64(this.files).then(
      (data: any) => {
        this.pdfContent =
          URL.createObjectURL(this.b64toBlob(data.split("data:application/pdf;base64,")[1], 'application/pdf')) +
          '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

        this.pdfview.nativeElement.setAttribute('data', this.pdfContent);
      });

  }

  //only number will be add
  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.form.value['active'] = true;
    this.form.value['notLocked'] = true;
    this.form.value['dateBirth'] = this.datepipe.transform(this.form.value['dateBirth'], 'dd/MM/yyyy');
    this.form.value['dateRecruitment'] = this.datepipe.transform(this.form.value['dateRecruitment'], 'dd/MM/yyyy');
    const formData = this.userService.createUserFormDate(null!, this.form.value, this.profileImage);
    this.subscriptions.push(
      this.userService.addUser(formData).subscribe(
        (response: User) => {
          this.getUsers(false);
          this.fileName = null!;
          this.profileImage = null!;
          this.form.reset();
          this.sendNotification(NotificationType.SUCCESS, `${response.firstName} ${response.lastName} added successfully`);
          this.getUsers(false);
          this.router.navigateByUrl('/controlpanel/accessmanagement');
        },
        (errorResponse: HttpErrorResponse) => {
          this.sendNotification(NotificationType.ERROR, errorResponse.error.message);
          this.profileImage = null!;
        }
      )
    );
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  private b64toBlob(b64Data: any, contentType: any) {
    var byteCharacters = atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  private sendNotification(notificationType: NotificationType, message: string): void {
    if (message) {
      this.notificationService.notify(notificationType, message);
    } else {
      this.notificationService.notify(notificationType, 'An error occurred. Please try again.');
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}